import React from 'react'
import './Projects.css'
import arrow from '../../assets/images/arrow.png'
import projectOne from '../../assets/images/projectOne.png'
import projectTwo from '../../assets/images/projectTwo.png'
import projectThree from '../../assets/images/projectThree.png'

export default function Projects() {
    function toContact() {
        window.location.href="./#contact";
    }

    return (
        <section id="projects">
            <p className="section__text__p1">Browse My Recent</p>
            <h1 className="title">Projects</h1>
            <div className="experience-details-container">
                <div className="about-containers">
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img src={projectOne} alt="project one" className="project-img" />
                        </div>
                        <h2 className="experience-sub-title project-title">Project One</h2>
                        <div className="btn-container">
                            <button className="btn btn-color-2 project-btn">Github</button>
                            <button className="btn btn-color-2 project-btn">Live Demo</button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img src={projectTwo} alt="project one" className="project-img" />
                        </div>
                        <h2 className="experience-sub-title project-title">Project One</h2>
                        <div className="btn-container">
                            <button className="btn btn-color-2 project-btn">Github</button>
                            <button className="btn btn-color-2 project-btn">Live Demo</button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img src={projectThree} alt="project one" className="project-img" />
                        </div>
                        <h2 className="experience-sub-title project-title">Project One</h2>
                        <div className="btn-container">
                            <button className="btn btn-color-2 project-btn">Github</button>
                            <button className="btn btn-color-2 project-btn">Live Demo</button>
                        </div>
                    </div>
                </div>
            </div>
            <img src={arrow} alt="arrow icon" className="icon arrow" onClick={toContact}/>
        </section>
    )
}