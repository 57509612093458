import React from 'react'
import './Hero.css'
import profilePic from '../../assets/images/profilePic.png'
import linkedinLogo from '../../assets/images/linkedin.png'
import githubLogo from '../../assets/images/github.png'
import Resume from '../../assets/images/ErikJohnsResume.pdf'

export default function Hero() {
    function linkedIn() {
        window.open('https://www.linkedin.com/in/erik-johns');
    }

    function github() {
        window.open('https://www.github.com/erikjohns');
    }

    function seeContact() {
        window.location.href='./#contact';
    }

    function seeResume() {
        window.open(Resume);
    }

    return (
        <section id="profile">
            <div class="section__pic-container">
                <img src={profilePic} alt="Erik Johns Profile Picture" className="profile-pic"/>
            </div>
            <div className="section__text">
                <p className="section__text__p1">Hello, I'm</p>
                <h1 class="title">Erik Johns</h1>
                <p className="section__text__p2">Computer Science Student</p>
                <div className="btn-container">
                    <button class="btn btn-color-2" onClick={seeResume}>See Resume</button>
                    <button class="btn btn-color-1" onClick={seeContact}>Contact</button>
                </div>
                <div id="socials-container">
                    <img
                        src={linkedinLogo}
                        alt="LinkedIn logo"
                        className="icon"
                        onClick={linkedIn} />
                    <img
                        src={githubLogo}
                        alt="Github logo"
                        className="icon"
                        onClick={github} />
                </div>
            </div>
        </section>
    )
}

