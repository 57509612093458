import React from 'react'
import './Contact.css'
import email from '../../assets/images/email.png'
import linkedin from '../../assets/images/linkedin.png'

export default function Contact() {
    return (
        <section id="contact">
            <p className="section__text__p1">Get in Touch</p>
            <h1 className="title">Contact Me</h1>
            <div className="contact-info-upper-container">
                <div className="contact-info-container">
                    <img src={email} alt="email icon" className="icon contact-icon email-icon" />
                    <p><a href="mailto:erikj3520@gmail.com">erikj3520@gmail.com</a></p>
                </div>
                <div className="contact-info-container">
                    <img src={linkedin} alt="linkedin icon" className="icon contact-icon"/>
                    <p><a href="https://www.linkedin.com/in/erik-johns/">LinkedIn</a></p>
                </div>
            </div>
        </section>
    )
}