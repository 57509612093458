import React, { useState } from 'react';
import './Navbar.css';

export default function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <header>
            <nav id="desktop-nav">
                <div className="logo">Erik Johns</div>
                <div>
                    <ul className="nav-links">
                        <li><a href="#about">About</a></li>
                        <li><a href="#experience">Experience</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </nav>
            <nav id="hamburger-nav">
                <div className="logo">Erik Johns</div>
                <div className="hamburger-menu">
                    <div className={`hamburger-icon ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    {menuOpen && (
                        <div className="menu-links">
                            <ul>
                                <li><a href="#">About</a></li>
                                <li><a href="#">Experience</a></li>
                                <li><a href="#">Projects</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul>
                        </div>
                    )}
                </div>
            </nav>
        </header>
    );
}
