import React from 'react'
import './About.css'
import aboutPic from '../../assets/images/aboutPic.png'
import experiencePic from '../../assets/images/experience.png'
import educationPic from '../../assets/images/education.png'
import arrow from '../../assets/images/arrow.png'

export default function About() {
    function toExperience() {
        window.location.href="./#experience";
    }

    return (
        <section id="about">
            <p className="section__text__p1">Get To Know More</p>
            <h1 className="title">About Me</h1>
            <div className="section-container">
                <div className="section__pic-container">
                    <img src={aboutPic} alt="about me picture" className="about-pic"/>
                </div>
                <div className="about-details-container">
                    <div className="about-containers">
                        <div className="details-container">
                            <img src={experiencePic} alt="experience icon" className="icon" />
                            <h3>Experience</h3>
                            <p>2+ years<br />Frontend Development</p>
                        </div>
                        <div className="details-container">
                            <img src={educationPic} alt="education icon" className="icon" />
                            <h3>Education</h3>
                            <p>B.S. Computer Science<br />Montana State University</p>
                        </div>
                    </div>
                    <div class="text-container">
                        <p>
                            Hi! My name is Erik Johns. I am currently a Computer Science student at Montana State
                            University. I am in my third year as a student here, and I'm really enjoying my CS 
                            coursework. Right now, I'm currently looking for an internship position for Summer
                            2024. I am a highly motivated worker who is totally open to relocation. While you're here,
                            feel free to check out some of the projects I've worked on!
                        </p>
                    </div>
                </div>
            </div>
            <img src={arrow} alt="arrow icon" className="icon arrow" onClick={toExperience}/>
        </section>
    )
}